@import 'borders.css';
@import 'flex_lib.css';
@import 'fonts.css';
@import 'sizes.css';

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-style: none;
  background-color: transparent;
}

:root {
  --color_1: rgba(14, 14, 14, 1.0);
  --color_1_hovered: rgba(35, 35, 35, 1.0);
  --color_1_semi_transparent: rgba(14, 14, 14, 0.5);
  --color_2: rgba(128, 128, 128, 1.0);
  --color_2_semi_transparent: rgba(128, 128, 128, 0.3);
  --color_3: rgba(224,224,224,1.0);
  --color_3_semi_transparent_for_background: rgba(224,224,224,0.4);
  --color_3_hovered: rgba(194,194,194,1.0);
  --color_3_semi_transparent: rgba(224,224,224 ,0.6);
}

.root_element {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  height: auto;
  width: 100%;
  margin: 0pt;
  padding: 0pt;
  background-color: transparent;
  border-style: none;
}
#layer_3 {
  display: block;
  height: auto;
  min-height: 300px;
  width: 100%;
  margin: 0%;
  padding: 0%;
}

footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  height: 60pt;
  width: 100%;
  margin: 0%;
  padding: 0pt 9pt;
  background-color: var(--color_1);
  border-top-style: solid;
  border-top-color: var(--color_3);
  border-top-width: 3px; 
}
.main_home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
          align-content: space-between;
  height: -webkit-calc(100% - 70pt - 60pt);
  height: -moz-calc(100% - 70pt - 60pt);
  height: calc(100% - 70pt - 60pt);
  width: 100%;
  margin: 0%;
  padding: 0%;
  background-image: url('../images/image_greeting.png'), -webkit-gradient(linear, left top, left bottom, from(var(--color_3_semi_transparent_for_background)), to(var(--color_3_semi_transparent_for_background)));
  background-image: url('../images/image_greeting.png'), -webkit-linear-gradient(var(--color_3_semi_transparent_for_background), var(--color_3_semi_transparent_for_background));
  background-image: url('../images/image_greeting.png'), -moz-linear-gradient(var(--color_3_semi_transparent_for_background), var(--color_3_semi_transparent_for_background));
  background-image: url('../images/image_greeting.png'), -o-linear-gradient(var(--color_3_semi_transparent_for_background), var(--color_3_semi_transparent_for_background));
  background-image: url('../images/image_greeting.png'), linear-gradient(var(--color_3_semi_transparent_for_background), var(--color_3_semi_transparent_for_background));
  background-repeat: no-repeat, no-repeat;
  -webkit-background-size: cover, cover;
     -moz-background-size: cover, cover;
       -o-background-size: cover, cover;
          background-size: cover, cover;
  background-attachment: fixed;
  background-blend-mode: overlay;
}
#main_contents_holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
          align-content: space-between;
  height: -webkit-calc(100% - 70pt - 60pt);
  height: -moz-calc(100% - 70pt - 60pt);
  height: calc(100% - 70pt - 60pt);
  width: 100%;
  margin: 0%;
  padding: 0%;
  background-color: var(--color_1);
  padding: 20pt;
}
header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;  
  height: 70pt;
  width: 100%;
  margin: 0%;
  padding: 0%;
  background-color: var(--color_1);
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--color_3);
}

#article {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  height: auto;
  width: 100%;
  margin: 0%;
  padding: 0%;
  background-color: var(--color_1);
}
.carousel_image_holder {
  width: 100%;
  height: 265px;
  padding: 3px !important;
  margin: 15px 0px 15px 0px !important;
}
.carousel_image {
  height: auto;
  max-height: 70%;
}
/*display*/
.shown_flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.shown_inline_block {
  display: inline-block;
}
.shown_block {
  display: block;
}
.shown_inline {
  display: inline;
}
.no_display {
  display: none;
}
.hidden {
  visibility: hidden;
}
/*backgrounds*/
.color_1_object {
  background-color: var(--color_1);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_1_hovered_object {
  background-color: var(--color_1_hovered);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_1_semi_transparent_object {
  background-color: var(--color_1_semi_transparent);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_2_object {
  background-color: var(--color_2);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_2_semi_transparent_object {
  background-color: var(--color_2_semi_transparent);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_3_object {
  background-color: var(--color_3);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_3_hovered_object {
  background-color: var(--color_3_hovered);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.color_3_semi_transparent_object {
  background-color: var(--color_3_semi_transparent);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.transparent_object {
  background-color: transparent;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
/*opacity*/
.opaque {
  opacity: 1;
}
.opacity_9 {
  opacity: 0.9;
}
.opacity_8 {
  opacity: 0.8;
}
.opacity_7 {
  opacity: 0.7;
}
.opacity_6 {
  opacity: 0.6;
}
.opacity_5 {
  opacity: 0.5;
}
.opacity_4 {
  opacity: 0.4;
}
.opacity_3 {
  opacity: 0.3;
}
.opacity_2 {
  opacity: 0.2;
}
.opacity_1 {
  opacity: 0.1;
}
.non_opaque {
  opacity: 0;
}
/*position*/
.position_relative {
  position: relative;
}
.position_absolute {
  position: absolute;
}
.position_fixed {
  position: fixed;
}
.position_sticky {
  position: -webkit-sticky;
  position: sticky;
}
.position_up_left {
  top: 0%;
  left: 0%;
}
.position_up_right {
  top: 0%;
  right: 0%;
}
.position_down_left {
  bottom: 0%;
  left: 0%;
}
.position_down_right {
  bottom: 0%;
  right: 0%;
}
.position_top_100 {
  top: 100%;
  right: 0%;
}
/*outline*/
.no_outline {
  outline: 0;
}
/*z-index*/
.z_index_minus_1 {
  z-index: -1;
}
.z_index_1 {
  z-index: 1;
}
.z_index_2 {
  z-index: 2;
}
.z_index_3 {
  z-index: 3;
}
.z_index_4 {
  z-index: 4;
}
.z_index_5 {
  z-index: 5;
}
#layer_2 {
  background-image: url('../images/image_greeting.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}
.overflow_y {
  overflow-y: auto;
}
.image_contain {
  -o-object-fit: contain;
     object-fit: contain;
}
.image_portrait {
  height: 300px;
  width: auto;
}
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-height: 100vh;
  width: 100%;
  min-width: 250px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  background-color: transparent;
}
@keyframes carousel {
  0%   {opacity: 0;}
  15%  {opacity: 1;}
  75%  {opacity: 1;}
  100% {opacity: 0;}
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  #article {
    padding: 0px 15%;
  }
}
/*@media only screen and (min-width: 601px) and (max-width: 799px) {
  .carousel_image_holder {
    width: 40%;
    min-height: 300px;
  }
}*/
/*@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .carousel_image_holder {
    width: 70%;
    min-height: 300px;
  }
}*/
@media only screen and (min-width: 360px) and (max-width: 400px) {
  .carousel_image_holder {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  #main_contents_holder {
    padding: 40px 15%;
  }
  #article {
    padding: 0px 20%;
  }
  .carousel_image_holder {
    padding: 9px !important;
  }
  .carousel_image {

  }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
  .carousel_image_holder {
    height: 260px;
  }
  .carousel_image {
    /*max-height: 90%;*/
  }
}
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .carousel_image_holder {
    height: 280px;
  }
  .carousel_image {
    /*max-height: 90%;*/
  }
}
@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .carousel_image_holder {
    height: 300px;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1000px) {
  .carousel_image {
    max-height: 75%;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  .carousel_image_holder {
    height: 350px;
  }
  .carousel_image {
    max-height: 85%;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1600px) {
  .carousel_image_holder {
    height: 400px;
  }
  .carousel_image {
    max-height: 90%;
  }
}
@media only screen and (min-width: 1601px) {
  .carousel_image_holder {
    height: 500px;
  }
  .carousel_image {
    max-height: 90%;
  }
}

@media only screen and (orientation: landscape) {

}

@media only screen and (orientation: portrait) {
  
}
