/*borders*/
.non-bordered {
  border-style: none;
}
.bordered {
  border-style: solid;
}
.border_bottom {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: solid;
}
.border_top {
  border-top-style: solid;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none;
}
.border_top_bottom {
  border-top-style: solid;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: solid;
}
.border_left_bottom_right {
  border-top-style: none;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
}
.border_left_top_right {
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: none;
}
.border_left_top_bottom {
  border-top-style: solid;
  border-right-style: none;
  border-left-style: solid;
  border-bottom-style: solid;
}
.border_top_right_bottom {
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: none;
  border-bottom-style: solid;
}
.thin_border {
  border-width: 1.5px;
}
.extra_thin_border {
  border-width: 1px;
}
.medium_border {
  border-width: 3px;
}
.wide_border {
  border-width: 5px;
}
.color_1_border {
  border-color: var(--color_1);
}
.color_2_border {
  border-color: var(--color_2);  
}
.color_3_border {
  border-color: var(--color_3);
}
.transparent_border {
  border-color: transparent; 
}
.round_border {
  border-radius: 50%;
}
/*soft corner borders*/
.soft_corner_border {
  border-radius: 15px;
}
.soft_corner_bottom_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.soft_corner_top_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.soft_corner_left_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
}
.soft_corner_right_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 15px;
}
.soft_corner_top_left_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
}
.soft_corner_top_right_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 15px;
}
.soft_corner_bottom_right_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.soft_corner_bottom_left_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.soft_corner_left_top_right_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
}
.soft_corner_top_right_bottom_border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.soft_corner_right_bottom_left_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 15px;
}
.soft_corner_bottom_left_top_border {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}