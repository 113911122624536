/*sizes*/
/*height*/
.height_100vh {
  height: 100vh;
}
.height_90vh {
  height: 90vh;
}
.height_85vh {
  height: 85vh;
}
.height_fit {
  height: fit-content;
}
.height_auto {
  height: auto;
}
.height_1_of_5 {
  height: 20%;
}
.height_2_of_5 {
  height: 40%;
}
.height_3_of_5 {
  height: 60%;
}
.height_4_of_5 {
  height: 80%;
}
.height_1 {
  height: 8.3%;
  min-height: 8.3%;
  max-height: 8.3%;
}
.height_2 {
  height: 16.6%;
  min-height: 16.6%;
  max-height: 16.6%;
}
.height_3 {
  height: 24.9%;
  min-height: 24.9%;
  max-height: 24.9%;
}
.height_4 {
  height: 33.2%;
  min-height: 33.2%;
  max-height: 33.2%;
}
.height_5 {
  height: 41.5%;
  min-height: 41.5%;
  max-height: 41.5%;
}
.height_6 {
  height: 49.8%;
  min-height: 49.8%;
  max-height: 49.8%;
}
.height_7 {
  height: 58.1%;
  min-height: 58.1%;
  max-height: 58.1%;
}
.height_8 {
  height: 66.4%;
  min-height: 66.4%;
  max-height: 66.4%;
}
.height_9 {
  height: 74.7%;
  min-height: 74.7%;
  max-height: 74.7%;
}
.height_10 {
  height: 83%;
  min-height: 83%;
  max-height: 83%;
}
.height_11 {
  height: 91.3%;
  min-height: 91.3%;
  max-height: 91.3%;
}
.height_12 {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
.height_13 {
  height: 108.3%;
}
.height_14 {
  height: 116.6%;
}
.height_15 {
  height: 124.9%;
}
.height_16 {
  height: 133.2%;
}
.height_17 {
  height: 141.5%;
}
.height_18 {
  height: 149.8%;
}
.height_19 {
  height: 158.1%;
}
.height_20 {
  height: 166.4%;
}
.height_21 {
  height: 174.7%;
}
.height_22 {
  height: 183%;
}
.height_23 {
  height: 191.3%;
}
.height_24 {
  height: 200%;
}
.height_25 {
  height: 208.3%;
}
.height_26 {
  height: 216.6%;
}
.height_27 {
  height: 224.9%;
}
.height_28 {
  height: 233.2%;
}
.height_29 {
  height: 241.5%;
}
.height_30 {
  height: 249.8%;
}
.height_31 {
  height: 258.1%;
}
.height_32 {
  height: 266.4%;
}
.height_33 {
  height: 274.7%;
}
.height_34 {
  height: 283%;
}
.height_35 {
  height: 291.3%;
}
.height_36 {
  height: 300%;
}
.height_37 {
  height: 308.3%;
}
.height_38 {
  height: 316.6%;
}
.height_39 {
  height: 324.9%;
}
.height_40 {
  height: 333.2%;
}
.height_41 {
  height: 341.5%;
}
.height_42 {
  height: 349.8%;
}
.height_43 {
  height: 358.1%;
}
.height_44 {
  height: 366.4%;
}
.height_45 {
  height: 374.7%;
}
.height_46 {
  height: 383%;
}
.height_47 {
  height: 391.3%;
}
.height_48 {
  height: 400%;
}
/*min height*/ 
.min_height_1 {
  min-height: 8.3%;
}
.min_height_2 {
  min-height: 16.6%;
}
.min_height_3 {
  min-height: 24.9%;
}
.min_height_4 {
  min-height: 33.2%;
}
.min_height_5 {
  min-height: 41.5%;
}
.min_height_6 {
  min-height: 49.8%;
}
.min_height_7 {
  min-height: 58.1%;
}
.min_height_8 {
  min-height: 66.4%;
}
.min_height_9 {
  min-height: 74.7%;
}
.min_height_10 {
  min-height: 83%;
}
.min_height_11 {
  min-height: 91.3%;
}
.min_height_12 {
  min-height: 100%;
}
.min_height_95vh {
  min-height: 95vh;
}
/*max height*/ 
.max_height_1 {
  max-height: 8.3%;
}
.max_height_2 {
  max-height: 16.6%;
}
.max_height_3 {
  max-height: 24.9%;
}
.max_height_4 {
  max-height: 33.2%;
}
.max_height_5 {
  max-height: 41.5%;
}
.max_height_6 {
  max-height: 49.8%;
}
.max_height_7 {
  max-height: 58.1%;
}
.max_height_8 {
  max-height: 66.4%;
}
.max_height_9 {
  max-height: 74.7%;
}
.max_height_10 {
  max-height: 83%;
}
.max_height_11 {
  max-height: 91.3%;
}
.max_height_12 {
  max-height: 100%;
}
/*width*/
.width_100vw {
  width: 100vw;
}
.max_width_100vw {
  max-width: 100vw;
}
.max_width_95vw {
  max-width: 95vw;
}
.width_95vw {
  width: 95vw;
}
.width_fit {
  width: fit-content;
}
.width_auto {
  width: auto;
}
.width_1 {
  width: 8.3%;
}
.width_2 {
  width: 16.6%;
}
.width_3 {
  width: 24.9%;
}
.width_4 {
  width: 33.2%;
}
.width_5 {
  width: 41.5%;
}
.width_6 {
  width: 49.8%;
}
.width_7 {
  width: 58.1%;
}
.width_8 {
  width: 66.4%;
}
.width_9 {
  width: 74.7%;
}
.width_10 {
  width: 83%;
}
.width_11 {
  width: 91.3%;
}
.width_12 {
  width: 100%;
}
/*margins*/
.margin_0 {
  margin: 0% !important;
}
.margin_3_global {
  margin: 3px !important;
}
.margin_3_top {
  margin: 3px 0px 0px 0px !important;
}
.margin_3_right {
  margin: 0px 3px 0px 0px !important;  
}
.margin_3_bottom {
  margin: 0px 0px 3px 0px !important;  
}
.margin_3_left {
  margin: 0px 0px 0px 3px !important;  
}
.margin_3_top_bottom {
  margin: 3px 0px !important;
}
.margin_3_left_right {
  margin: 0px 3px !important;
}
.margin_3_bottom_left {
  margin: 0px 0px 3px 3px !important;
}
.margin_3_bottom_right {
  margin: 0px 3px 3px 0px !important;
}
.margin_3_top_left {
  margin: 3px 0px 0px 3px !important;
}
.margin_3_top_right {
  margin: 3px 3px 0px 0px !important;
}
.margin_3_top_right_bottom {
  margin: 3px 3px 3px 0px !important;
}
.margin_3_right_bottom_left {
  margin: 0px 3px 3px 3px !important;
}
.margin_3_bottom_left_top {
  margin: 3px 0px 3px 3px !important;
}
.margin_3_left_top_right {
  margin: 3px 3px 0px 3px !important;
}
/*padding*/
.padding_60_top {
  padding: 60px 9px 9px 9px; 
}
.padding_0 {
  padding: 0% !important;
}
.padding_3_global {
  padding: 3px !important;
}
.padding_3_top {
  padding: 3px 0px 0px 0px !important;
}
.padding_3_right {
  padding: 0px 3px 0px 0px !important;  
}
.padding_3_bottom {
  padding: 0px 0px 3px 0px !important;  
}
.padding_3_left {
  padding: 0px 0px 0px 3px !important;  
}
.padding_3_top_bottom {
  padding: 3px 0px !important;
}
.padding_3_left_right {
  padding: 0px 3px !important;
}
.padding_3_bottom_left {
  padding: 0px 0px 3px 3px !important;
}
.padding_3_bottom_right {
  padding: 0px 3px 3px 0px !important;
}
.padding_3_top_left {
  padding: 3px 0px 0px 3px !important;
}
.padding_3_top_right {
  padding: 3px 3px 0px 0px !important;
}
.padding_3_top_right_bottom {
  padding: 3px 3px 3px 0px !important;
}
.padding_3_right_bottom_left {
  padding: 0px 3px 3px 3px !important;
}
.padding_3_bottom_left_top {
  padding: 3px 0px 3px 3px !important;
}
.padding_3_left_top_right {
  padding: 3px 3px 0px 3px !important;
}

.padding_6_global {
  padding: 6px !important;
}
.padding_6_top {
  padding: 6px 0px 0px 0px !important;
}
.padding_6_right {
  padding: 0px 6px 0px 0px !important;  
}
.padding_6_bottom {
  padding: 0px 0px 6px 0px !important;  
}
.padding_6_left {
  padding: 0px 0px 0px 6px !important;  
}
.padding_6_top_bottom {
  padding: 6px 0px !important;
}
.padding_6_left_right {
  padding: 0px 6px !important;
}
.padding_6_bottom_left {
  padding: 0px 0px 6px 6px !important;
}
.padding_6_bottom_right {
  padding: 0px 6px 6px 0px !important;
}
.padding_6_top_left {
  padding: 6px 0px 0px 6px !important;
}
.padding_6_top_right {
  padding: 6px 6px 0px 0px !important;
}
.padding_6_top_right_bottom {
  padding: 6px 6px 6px 0px !important;
}
.padding_6_right_bottom_left {
  padding: 0px 6px 6px 6px !important;
}
.padding_6_bottom_left_top {
  padding: 6px 0px 6px 6px !important;
}
.padding_6_left_top_right {
  padding: 6px 6px 0px 6px !important;
}



.padding_9_global {
  padding: 9px !important;
}
.padding_9_top {
  padding: 9px 0px 0px 0px !important;
}
.padding_9_right {
  padding: 0px 9px 0px 0px !important;  
}
.padding_9_bottom {
  padding: 0px 0px 9px 0px !important;  
}
.padding_9_left {
  padding: 0px 0px 0px 9px !important;  
}
.padding_9_top_bottom {
  padding: 9px 0px !important;
}
.padding_9_left_right {
  padding: 0px 9px !important;
}
.padding_9_bottom_left {
  padding: 0px 0px 9px 9px !important;
}
.padding_9_bottom_right {
  padding: 0px 9px 9px 0px !important;
}
.padding_9_top_left {
  padding: 9px 0px 0px 9px !important;
}
.padding_9_top_right {
  padding: 9px 9px 0px 0px !important;
}
.padding_9_top_right_bottom {
  padding: 9px 9px 9px 0px !important;
}
.padding_9_right_bottom_left {
  padding: 0px 9px 9px 9px !important;
}
.padding_9_bottom_left_top {
  padding: 9px 0px 9px 9px !important;
}
.padding_9_left_top_right {
  padding: 9px 9px 0px 9px !important;
}
.padding_20_left {
  padding: 3px 0px 3px 20px !important;
}
.padding_10_bottom {
  padding: 6px 0px 10px 6px !important;
}
.padding_20_left_10_bottom {
  padding: 6px 0px 10px 20px !important;
}
.padding_10_6_3_6 {
  padding: 10px 6px 3px 6px !important;
}