/*fonts*/
* {
  text-decoration:none;
}

.color_1_font {
  color: var(--color_1);
}
.color_2_font {
  color: var(--color_2);
}
.color_3_font {
  color: var(--color_3);
}
.centered_font {
  text-align: center;
}
.right_font {
  text-align: right;
}
.justified_font {
  text-align: justify;
}
.left_font {
  text-align: left;
}
.italic_font {
  font-style: italic;
}
/*full support Chrome 57, EDGE 12.0 (webkit), Mozilla 51, Safari 10.1, Opera 44*/
::placeholder {
  color: var(--color_1);
  opacity: 1;
  font-style: italic;
}
.montserrat_600 {
  font-family: "Montserrat", "Arial", "sans-serif";
  font-weight: 600;
}
.montserrat_700 {
  font-family: "Montserrat", "Arial", "sans-serif";
  font-weight: 700; 
}
.noto_sans {
  font-family: "Noto Sans", "Arial", "sans-serif";
  font-weight: 400;
}
.extra_large_font {
  font-size: 28px;
  letter-spacing: 2pt;
}
.large_font {
  font-size: 18px;
  letter-spacing: 2pt;
}
.medium_font {
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 1.2; 
}
.small_font {
  font-size: 12px;
}
.medium_small_font {
  font-size: 10px;
}
.extra_small_font {
  font-size: 10px;
}
.large_icons_font {
  font-size: 36px !important;
}
.small_icons_font {
  font-size: 26px !important;
}
.material-icons {
  margin: 0%;
  text-align: justify;
  text-decoration: none;
  white-space: nowrap;
  outline: 0;
}
.overflow_ellipsis {
  text-overflow: ellipsis;
}
.text_with_intendation {
  text-indent: 20px;
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .extra_large_font {
    font-size: 6vmin;
  }
  .large_font {
    /*font-size: 4vmin;*/
    font-size: 16pt;
  }
  .medium_font {
    font-size: 17px; 
  }
  .small_font {
    font-size: 14px;
  }
  .medium_small_font {
    font-size: 12px;
  }
  .extra_small_font {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .extra_large_font {
    font-size: 6vmin;
  }
  .large_font {
    /*font-size: 4vmin;*/
    font-size: 20pt;
  }
  .medium_font {
    font-size: 17px; 
  }
  .small_font {
    font-size: 14px;
  }
  .medium_small_font {
    font-size: 12px;
  }
  .extra_small_font {
    font-size: 10px;
  }
}