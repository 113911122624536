/*
flex-direction
justify-content
align-items
align-content
*/

/*f - flex
r- row
col - column
c - center
fe - flex-end
fs - flex-start
sa - space-around
sb - space-between
s - stretch
b - baseline*/


.wrappable {
    flex-wrap: wrap;
}
.non-wrappable {
    flex-wrap: nowrap;
}

/*row justify-content: center iterations*/

    /*align-items: center iterations*/
.f-r-c-c-c {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: center;   
}
.f-r-c-c-fe {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: flex-end;   
}
.f-r-c-c-fs {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: flex-start;   
}
.f-r-c-c-sa {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: space-around;   
}
.f-r-c-c-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: space-between;   
}
.f-r-c-c-s {
     
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    align-content: stretch;   
}
    /*align-items: baseline iterations*/
.f-r-c-b-c {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: center;   
}
.f-r-c-b-fe {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: flex-end;   
}
.f-r-c-b-fs {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: flex-start;   
}
.f-r-c-b-sa {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: space-around;   
}
.f-r-c-b-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: space-between;   
}
.f-r-c-b-s {
     
    flex-direction: row;
    justify-content: center; 
    align-items: baseline;
    align-content: stretch;   
}
    /*align-items: flex-end iterations*/
.f-r-c-fe-c {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: center;   
}
.f-r-c-fe-fe {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: flex-end;   
}
.f-r-c-fe-fs {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: flex-start;   
}
.f-r-c-fe-sa {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: space-around;   
}
.f-r-c-fe-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: space-between;   
}
.f-r-c-fe-s {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-end;
    align-content: stretch;   
}
    /*align-items: flex-start iterations*/
.f-r-c-fs-c {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: center;   
}
.f-r-c-fs-fe {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: flex-end;   
}
.f-r-c-fs-fs {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: flex-start;   
}
.f-r-c-fs-sa {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: space-around;   
}
.f-r-c-fs-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: space-between;   
}
.f-r-c-fs-s {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: stretch;   
}
    /*align-items: stretch iterations*/
.f-r-c-s-c {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: center;   
}
.f-r-c-s-fe {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: flex-end;   
}
.f-r-c-s-fs {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: flex-start;   
}
.f-r-c-s-sa {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: space-around;   
}
.f-r-c-s-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: space-between;   
}
.f-r-c-s-s {
     
    flex-direction: row;
    justify-content: center; 
    align-items: stretch;
    align-content: stretch;   
}
/*row justify-content: flex-end iterations*/

    /*align-items: center iterations*/
.f-r-fe-c-c {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: center;   
}
.f-r-fe-c-fe {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: flex-end;   
}
.f-r-fe-c-fs {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: flex-start;   
}
.f-r-fe-c-sa {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: space-around;   
}
.f-r-fe-c-sb {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: space-between;   
}
.f-r-fe-c-s {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: center;
    align-content: stretch;   
}
    /*align-items: baseline iterations*/
.f-r-fe-b-c {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: center;   
}
.f-r-fe-b-fe {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: flex-end;   
}
.f-r-fe-b-fs {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: flex-start;   
}
.f-r-fe-b-sa {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: space-around;   
}
.f-r-fe-b-sb {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: space-between;   
}
.f-r-fe-b-s {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: baseline;
    align-content: stretch;   
}
    /*align-items: flex-end iterations*/
.f-r-fe-fe-c {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: center;   
}
.f-r-fe-fe-fe {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: flex-end;   
}
.f-r-fe-fe-fs {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: flex-start;   
}
.f-r-fe-fe-sa {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: space-around;   
}
.f-r-fe-fe-sb {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: space-between;   
}
.f-r-fe-fe-s {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-end;
    align-content: stretch;   
}
    /*align-items: flex-start iterations*/
.f-r-fe-fs-c {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: center;   
}
.f-r-fe-fs-fe {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: flex-end;   
}
.f-r-fe-fs-fs {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: flex-start;   
}
.f-r-fe-fs-sa {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: space-around;   
}
.f-r-fe-fs-sb {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: space-between;   
}
.f-r-fe-fs-s {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: flex-start;
    align-content: stretch;   
}
    /*align-items: stretch iterations*/
.f-r-fe-s-c {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: center;   
}
.f-r-fe-s-fe {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: flex-end;   
}
.f-r-fe-s-fs {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: flex-start;   
}
.f-r-fe-s-sa {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: space-around;   
}
.f-r-fe-s-sb {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: space-between;   
}
.f-r-fe-s-s {
     
    flex-direction: row;
    justify-content: flex-end; 
    align-items: stretch;
    align-content: stretch;   
}

/*row justify-content: flex-start iterations*/

/*align-items: center iterations*/
.f-r-fs-c-c {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: center;   
}
.f-r-fs-c-fe {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: flex-end;   
}
.f-r-fs-c-fs {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: flex-start;   
}
.f-r-fs-c-sa {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: space-around;   
}
.f-r-fs-c-sb {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: space-between;   
}
.f-r-fs-c-s {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
    align-content: stretch;   
}
    /*align-items: baseline iterations*/
.f-r-fs-b-c {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: center;   
}
.f-r-fs-b-fe {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: flex-end;   
}
.f-r-fs-b-fs {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: flex-start;   
}
.f-r-fs-b-sa {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: space-around;   
}
.f-r-fs-b-sb {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: space-between;   
}
.f-r-fs-b-s {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: baseline;
    align-content: stretch;   
}
    /*align-items: flex-end iterations*/
.f-r-fs-fe-c {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: center;   
}
.f-r-fs-fe-fe {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: flex-end;   
}
.f-r-fs-fe-fs {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: flex-start;   
}
.f-r-fs-fe-sa {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: space-around;   
}
.f-r-fs-fe-sb {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: space-between;   
}
.f-r-fs-fe-s {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-end;
    align-content: stretch;   
}
    /*align-items: flex-start iterations*/
.f-r-fs-fs-c {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: center;   
}
.f-r-fs-fs-fe {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: flex-end;   
}
.f-r-fs-fs-fs {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: flex-start;   
}
.f-r-fs-fs-sa {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: space-around;   
}
.f-r-fs-fs-sb {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: space-between;   
}
.f-r-fs-fs-s {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start;
    align-content: stretch;   
}
    /*align-items: stretch iterations*/
.f-r-fs-s-c {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: center;   
}
.f-r-fs-s-fe {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: flex-end;   
}
.f-r-fs-s-fs {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: flex-start;   
}
.f-r-fs-s-sa {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: space-around;   
}
.f-r-fs-s-sb {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: space-between;   
}
.f-r-fs-s-s {
     
    flex-direction: row;
    justify-content: flex-start; 
    align-items: stretch;
    align-content: stretch;   
}
/*row justify-content: space-around iterations*/

    /*align-items: center iterations*/
.f-r-sa-c-c {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: center;   
}
.f-r-sa-c-fe {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: flex-end;   
}
.f-r-sa-c-fs {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: flex-start;   
}
.f-r-sa-c-sa {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: space-around;   
}
.f-r-sa-c-sb {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: space-between;   
}
.f-r-sa-c-s {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    align-content: stretch;   
}
    /*align-items: baseline iterations*/
.f-r-sa-b-c {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: center;   
}
.f-r-sa-b-fe {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: flex-end;   
}
.f-r-sa-b-fs {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: flex-start;   
}
.f-r-sa-b-sa {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: space-around;   
}
.f-r-sa-b-sb {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: space-between;   
}
.f-r-sa-b-s {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: baseline;
    align-content: stretch;   
}
    /*align-items: flex-end iterations*/
.f-r-sa-fe-c {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: center;   
}
.f-r-sa-fe-fe {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: flex-end;   
}
.f-r-sa-fe-fs {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: flex-start;   
}
.f-r-sa-fe-sa {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: space-around;   
}
.f-r-sa-fe-sb {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: space-between;   
}
.f-r-sa-fe-s {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-end;
    align-content: stretch;   
}
    /*align-items: flex-start iterations*/
.f-r-sa-fs-c {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: center;   
}
.f-r-sa-fs-fe {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: flex-end;   
}
.f-r-sa-fs-fs {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: flex-start;   
}
.f-r-sa-fs-sa {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: space-around;   
}
.f-r-sa-fs-sb {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: space-between;   
}
.f-r-sa-fs-s {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: flex-start;
    align-content: stretch;   
}
    /*align-items: stretch iterations*/
.f-r-sa-s-c {
     
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;   
}
.f-r-sa-s-fe {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: stretch;
    align-content: flex-end;   
}
.f-r-sa-s-fs {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: stretch;
    align-content: flex-start;   
}
.f-r-sa-s-sa {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: stretch;
    align-content: space-around;   
}
.f-r-sa-s-sb {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: stretch;
    align-content: space-between;   
}
.f-r-sa-s-s {
     
    flex-direction: row;
    justify-content: space-around; 
    align-items: stretch;
    align-content: stretch;   
}
/*row justify-content: space-between iterations*/
    /*align-items: center iterations*/
.f-r-sb-c-c {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: center;   
}
.f-r-sb-c-fe {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: flex-end;   
}
.f-r-sb-c-fs {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: flex-start;   
}
.f-r-sb-c-sa {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: space-around;   
}
.f-r-sb-c-sb {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: space-between;   
}
.f-r-sb-c-s {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: stretch;   
}
    /*align-items: baseline iterations*/
.f-r-sb-b-c {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: center;   
}
.f-r-sb-b-fe {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: flex-end;   
}
.f-r-sb-b-fs {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: flex-start;   
}
.f-r-sb-b-sa {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: space-around;   
}
.f-r-sb-b-sb {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: space-between;   
}
.f-r-sb-b-s {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: baseline;
    align-content: stretch;   
}
    /*align-items: flex-end iterations*/
.f-r-sb-fe-c {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: center;   
}
.f-r-sb-fe-fe {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: flex-end;   
}
.f-r-sb-fe-fs {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: flex-start;   
}
.f-r-sb-fe-sa {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: space-around;   
}
.f-r-sb-fe-sb {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: space-between;   
}
.f-r-sb-fe-s {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-end;
    align-content: stretch;   
}
    /*align-items: flex-start iterations*/
.f-r-sb-fs-c {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: center;   
}
.f-r-sb-fs-fe {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: flex-end;   
}
.f-r-sb-fs-fs {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: flex-start;   
}
.f-r-sb-fs-sa {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: space-around;   
}
.f-r-sb-fs-sb {
     
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    align-content: space-between;   
}
.f-r-sb-fs-s {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    align-content: stretch;   
}
    /*align-items: stretch iterations*/
.f-r-sb-s-c {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: center;   
}
.f-r-sb-s-fe {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: flex-end;   
}
.f-r-sb-s-fs {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: flex-start;   
}
.f-r-sb-s-sa {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: space-around;   
}
.f-r-sb-s-sb {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: space-between;   
}
.f-r-sb-s-s {
     
    flex-direction: row;
    justify-content: space-between; 
    align-items: stretch;
    align-content: stretch;   
}

/*column justify-content: center iterations*/

    /*align-items: center iterations*/
    .f-col-c-c-c {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: center;   
    }
    .f-col-c-c-fe {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: flex-end;   
    }
    .f-col-c-c-fs {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: flex-start;   
    }
    .f-col-c-c-sa {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: space-around;   
    }
    .f-col-c-c-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: space-between;   
    }
    .f-col-c-c-s {
         
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        align-content: stretch;   
    }
        /*align-items: baseline iterations*/
    .f-col-c-b-c {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: center;   
    }
    .f-col-c-b-fe {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: flex-end;   
    }
    .f-col-c-b-fs {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: flex-start;   
    }
    .f-col-c-b-sa {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: space-around;   
    }
    .f-col-c-b-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: space-between;   
    }
    .f-col-c-b-s {
         
        flex-direction: column;
        justify-content: center; 
        align-items: baseline;
        align-content: stretch;   
    }
        /*align-items: flex-end iterations*/
    .f-col-c-fe-c {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: center;   
    }
    .f-col-c-fe-fe {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: flex-end;   
    }
    .f-col-c-fe-fs {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: flex-start;   
    }
    .f-col-c-fe-sa {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: space-around;   
    }
    .f-col-c-fe-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: space-between;   
    }
    .f-col-c-fe-s {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-end;
        align-content: stretch;   
    }
        /*align-items: flex-start iterations*/
    .f-col-c-fs-c {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: center;   
    }
    .f-col-c-fs-fe {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: flex-end;   
    }
    .f-col-c-fs-fs {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: flex-start;   
    }
    .f-col-c-fs-sa {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: space-around;   
    }
    .f-col-c-fs-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: space-between;   
    }
    .f-col-c-fs-s {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: stretch;   
    }
        /*align-items: stretch iterations*/
    .f-col-c-s-c {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: center;   
    }
    .f-col-c-s-fe {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: flex-end;   
    }
    .f-col-c-s-fs {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: flex-start;   
    }
    .f-col-c-s-sa {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: space-around;   
    }
    .f-col-c-s-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: space-between;   
    }
    .f-col-c-s-s {
         
        flex-direction: column;
        justify-content: center; 
        align-items: stretch;
        align-content: stretch;   
    }
    /*column justify-content: flex-end iterations*/
    
        /*align-items: center iterations*/
    .f-col-fe-c-c {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: center;   
    }
    .f-col-fe-c-fe {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: flex-end;   
    }
    .f-col-fe-c-fs {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: flex-start;   
    }
    .f-col-fe-c-sa {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: space-around;   
    }
    .f-col-fe-c-sb {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: space-between;   
    }
    .f-col-fe-c-s {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: center;
        align-content: stretch;   
    }
        /*align-items: baseline iterations*/
    .f-col-fe-b-c {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: center;   
    }
    .f-col-fe-b-fe {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: flex-end;   
    }
    .f-col-fe-b-fs {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: flex-start;   
    }
    .f-col-fe-b-sa {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: space-around;   
    }
    .f-col-fe-b-sb {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: space-between;   
    }
    .f-col-fe-b-s {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: baseline;
        align-content: stretch;   
    }
        /*align-items: flex-end iterations*/
    .f-col-fe-fe-c {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: center;   
    }
    .f-col-fe-fe-fe {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: flex-end;   
    }
    .f-col-fe-fe-fs {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: flex-start;   
    }
    .f-col-fe-fe-sa {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: space-around;   
    }
    .f-col-fe-fe-sb {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: space-between;   
    }
    .f-col-fe-fe-s {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-end;
        align-content: stretch;   
    }
        /*align-items: flex-start iterations*/
    .f-col-fe-fs-c {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: center;   
    }
    .f-col-fe-fs-fe {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: flex-end;   
    }
    .f-col-fe-fs-fs {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: flex-start;   
    }
    .f-col-fe-fs-sa {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: space-around;   
    }
    .f-col-fe-fs-sb {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: space-between;   
    }
    .f-col-fe-fs-s {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: flex-start;
        align-content: stretch;   
    }
        /*align-items: stretch iterations*/
    .f-col-fe-s-c {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: center;   
    }
    .f-col-fe-s-fe {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: flex-end;   
    }
    .f-col-fe-s-fs {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: flex-start;   
    }
    .f-col-fe-s-sa {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: space-around;   
    }
    .f-col-fe-s-sb {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: space-between;   
    }
    .f-col-fe-s-s {
         
        flex-direction: column;
        justify-content: flex-end; 
        align-items: stretch;
        align-content: stretch;   
    }
    
    /*column justify-content: flex-start iterations*/
    
    /*align-items: center iterations*/
    .f-col-fs-c-c {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: center;   
    }
    .f-col-fs-c-fe {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: flex-end;   
    }
    .f-col-fs-c-fs {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: flex-start;   
    }
    .f-col-fs-c-sa {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: space-around;   
    }
    .f-col-fs-c-sb {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: space-between;   
    }
    .f-col-fs-c-s {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: center;
        align-content: stretch;   
    }
        /*align-items: baseline iterations*/
    .f-col-fs-b-c {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: center;   
    }
    .f-col-fs-b-fe {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: flex-end;   
    }
    .f-col-fs-b-fs {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: flex-start;   
    }
    .f-col-fs-b-sa {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: space-around;   
    }
    .f-col-fs-b-sb {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: space-between;   
    }
    .f-col-fs-b-s {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: baseline;
        align-content: stretch;   
    }
        /*align-items: flex-end iterations*/
    .f-col-fs-fe-c {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: center;   
    }
    .f-col-fs-fe-fe {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: flex-end;   
    }
    .f-col-fs-fe-fs {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: flex-start;   
    }
    .f-col-fs-fe-sa {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: space-around;   
    }
    .f-col-fs-fe-sb {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: space-between;   
    }
    .f-col-fs-fe-s {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-end;
        align-content: stretch;   
    }
        /*align-items: flex-start iterations*/
    .f-col-fs-fs-c {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: center;   
    }
    .f-col-fs-fs-fe {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: flex-end;   
    }
    .f-col-fs-fs-fs {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: flex-start;   
    }
    .f-col-fs-fs-sa {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: space-around;   
    }
    .f-col-fs-fs-sb {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: space-between;   
    }
    .f-col-fs-fs-s {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
        align-content: stretch;   
    }
        /*align-items: stretch iterations*/
    .f-col-fs-s-c {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: center;   
    }
    .f-col-fs-s-fe {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: flex-end;   
    }
    .f-col-fs-s-fs {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: flex-start;   
    }
    .f-col-fs-s-sa {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: space-around;   
    }
    .f-col-fs-s-sb {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: space-between;   
    }
    .f-col-fs-s-s {
         
        flex-direction: column;
        justify-content: flex-start; 
        align-items: stretch;
        align-content: stretch;   
    }
    /*column justify-content: space-around iterations*/
    
        /*align-items: center iterations*/
    .f-col-sa-c-c {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: center;   
    }
    .f-col-sa-c-fe {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: flex-end;   
    }
    .f-col-sa-c-fs {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: flex-start;   
    }
    .f-col-sa-c-sa {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: space-around;   
    }
    .f-col-sa-c-sb {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: space-between;   
    }
    .f-col-sa-c-s {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: center;
        align-content: stretch;   
    }
        /*align-items: baseline iterations*/
    .f-col-sa-b-c {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: center;   
    }
    .f-col-sa-b-fe {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: flex-end;   
    }
    .f-col-sa-b-fs {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: flex-start;   
    }
    .f-col-sa-b-sa {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: space-around;   
    }
    .f-col-sa-b-sb {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: space-between;   
    }
    .f-col-sa-b-s {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: baseline;
        align-content: stretch;   
    }
        /*align-items: flex-end iterations*/
    .f-col-sa-fe-c {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: center;   
    }
    .f-col-sa-fe-fe {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: flex-end;   
    }
    .f-col-sa-fe-fs {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: flex-start;   
    }
    .f-col-sa-fe-sa {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: space-around;   
    }
    .f-col-sa-fe-sb {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: space-between;   
    }
    .f-col-sa-fe-s {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-end;
        align-content: stretch;   
    }
        /*align-items: flex-start iterations*/
    .f-col-sa-fs-c {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: center;   
    }
    .f-col-sa-fs-fe {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: flex-end;   
    }
    .f-col-sa-fs-fs {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: flex-start;   
    }
    .f-col-sa-fs-sa {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: space-around;   
    }
    .f-col-sa-fs-sb {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: space-between;   
    }
    .f-col-sa-fs-s {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: flex-start;
        align-content: stretch;   
    }
        /*align-items: stretch iterations*/
    .f-col-sa-s-c {
         
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;
        align-content: center;   
    }
    .f-col-sa-s-fe {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: stretch;
        align-content: flex-end;   
    }
    .f-col-sa-s-fs {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: stretch;
        align-content: flex-start;   
    }
    .f-col-sa-s-sa {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: stretch;
        align-content: space-around;   
    }
    .f-col-sa-s-sb {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: stretch;
        align-content: space-between;   
    }
    .f-col-sa-s-s {
         
        flex-direction: column;
        justify-content: space-around; 
        align-items: stretch;
        align-content: stretch;   
    }
    /*column justify-content: space-between iterations*/
        /*align-items: center iterations*/
    .f-col-sb-c-c {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: center;   
    }
    .f-col-sb-c-fe {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: flex-end;   
    }
    .f-col-sb-c-fs {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: flex-start;   
    }
    .f-col-sb-c-sa {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: space-around;   
    }
    .f-col-sb-c-sb {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: space-between;   
    }
    .f-col-sb-c-s {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
        align-content: stretch;   
    }
        /*align-items: baseline iterations*/
    .f-col-sb-b-c {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: center;   
    }
    .f-col-sb-b-fe {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: flex-end;   
    }
    .f-col-sb-b-fs {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: flex-start;   
    }
    .f-col-sb-b-sa {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: space-around;   
    }
    .f-col-sb-b-sb {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: space-between;   
    }
    .f-col-sb-b-s {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: baseline;
        align-content: stretch;   
    }
        /*align-items: flex-end iterations*/
    .f-col-sb-fe-c {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: center;   
    }
    .f-col-sb-fe-fe {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: flex-end;   
    }
    .f-col-sb-fe-fs {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: flex-start;   
    }
    .f-col-sb-fe-sa {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: space-around;   
    }
    .f-col-sb-fe-sb {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: space-between;   
    }
    .f-col-sb-fe-s {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-end;
        align-content: stretch;   
    }
        /*align-items: flex-start iterations*/
    .f-col-sb-fs-c {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-start;
        align-content: center;   
    }
    .f-col-sb-fs-fe {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-start;
        align-content: flex-end;   
    }
    .f-col-sb-fs-fs {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-start;
        align-content: flex-start;   
    }
    .f-col-sb-fs-sa {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-start;
        align-content: space-around;   
    }
    .f-col-sb-fs-sb {
         
        flex-direction: column;
        justify-content: center; 
        align-items: flex-start;
        align-content: space-between;   
    }
    .f-col-sb-fs-s {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: flex-start;
        align-content: stretch;   
    }
        /*align-items: stretch iterations*/
    .f-col-sb-s-c {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: center;   
    }
    .f-col-sb-s-fe {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: flex-end;   
    }
    .f-col-sb-s-fs {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: flex-start;   
    }
    .f-col-sb-s-sa {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: space-around;   
    }
    .f-col-sb-s-sb {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: space-between;   
    }
    .f-col-sb-s-s {
         
        flex-direction: column;
        justify-content: space-between; 
        align-items: stretch;
        align-content: stretch;   
    }
    